/*LOGIN PAGE START*/
.login-logo {
  width: 160px;
  height: auto;
}

/*
.login-right-container {
  background-color: #bdd1ea;
}
.login-button {
  background-color: #257fb2;
  border-color: #257fb2;
}*/
/*LOGIN PAGE END*/

/*DASHBOARD PAGE START*/

/*DASHBOARD PAGE END*/
