//customize bootstrap
@import "./bs-custom";

$strong: #646c9a;
$border: #ccc7c7;
$shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
$body-bg: #f8f8f8;

///////////////
// Solid Color
///////////////
$white: #fff;
$black: #000;
$charade: #23252f;

/////////////////////////
// Dark Version Color
/////////////////////////
$d-ctd: #ddd;
$d-ctl: #828690;
$d-border: #3d3d3d;
$d-bg: #171717;
$dark-card: #202020;
$dark_bg_lighter: #1e2a4a;

/////////////////////////
// Primary Light
/////////////////////////
$primary-light: lighten($primary, 65%);
$secondary-light: lighten($secondary, 72%);
$success-light: lighten($success, 30%);
$warning-light: lighten($warning, 25%);
$danger-light: lighten($danger, 38%);
$info-light: lighten($info, 25%);
$dark-light: lighten($dark, 50%);

/////////////////////////
// Opacity
/////////////////////////
$primary-opacity: rgba($primary, 0.1);
$secondary-opacity: rgba($secondary, 0.1);
$success-opacity: rgba($success, 0.1);
$warning-opacity: rgba($warning, 0.1);
$danger-opacity: rgba($danger, 0.15);
$info-opacity: rgba($info, 0.1);
$dark-opacity: rgba($dark, 0.35);

////////////////////////
// Light Version Color
////////////////////////
$l-ctd: #464a53;
$l-ctl: #828690;
$l-border: #eaeaea;
$l-bg: #f2f4fa;
$heading: #333;

///////////////////
// Material Colors
///////////////////
$dusty-gray: #999999;
$gallery: #eeeeee;
$gray: #898989;
$input-border-color: $border;

///////////////////
// Social Colors
///////////////////
$facebook: #3b5998;
$twitter: #1da1f2;
$youtube: #ff0000;
$google-plus: #db4439;
$linkedin: #007bb6;
$instagram: #c32aa3;
$pinterest: #bd081c;
$google: #4285f4;
$snapchat: #fffc00;
$whatsapp: #25d366;
$tumblr: #35465d;
$reddit: #ff4500;
$spotify: #1ed760;
$yahoo: #430297;
$dribbble: #ea4c89;
$skype: #00aff0;
$quora: #aa2200;
$riverBed: #4c585c;
$vimeo: #1ab7ea;

///////////////////
// Misc Colors
///////////////////
$iron: #dddfe1;
$grey: #d2d6de;
$pale-sky: #6a707e;
$athensGray: #f0f0f2;
$sky: #2290ff;

$color_pallate_1: $white;
$color_pallate_2: #6610f2;
$color_pallate_3: $primary;
$color_pallate_4: #4d06a5;
$color_pallate_5: #dc3545;
$color_pallate_6: #fd7e14;
$color_pallate_7: #ffc107;
$color_pallate_8: #fff;
$color_pallate_9: #20c997;
$color_pallate_10: #17a2b8;
$color_pallate_11: #94618e;
$color_pallate_12: #343a40;
$color_pallate_13: #2a2a2a;
$color_pallate_14: #4885ed;
$color_pallate_15: #4cb32b;

//utility variables
$radius: 0.75rem;

$loader--width: 15.625rem;
$loader-dot--size: 1.25rem;
